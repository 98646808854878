/* 
        ImageWithText.module.css 
*/

.imageWithTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    width: 80%;
}

h2 {
    font-size: 40px;
    font-weight: bold;
    margin: 10px 0;
}

.title {
    font-family: 'Roie';
    width: 70%;
    padding-top: 3%;
    padding-bottom: 1%;
    color: #0B3782;
}

.paragraph {
    font-size: 16px;
    margin: 0;
    font-family: 'Prelia';
    width: 54%;
    color: #0B3782;
}

.universeInfo {
    margin-top: 17%;
}


@media (max-width: 868px) {
    .title {
        font-size: 28px;
        width: fit-content;
    }

    .paragraph {
        font-size: 12px;
        width: fit-content;
    }

    .image {
        width: 20rem;
    }
}