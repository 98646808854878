.bottomDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    padding: 15px 0;
    margin: 0 16%;
}

.iconContainer {
    display: flex;
    gap: 21px;
}

@media (max-width: 768px) {
    .bottomDiv {
        margin: 0 3.5%;
    }
}