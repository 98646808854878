/*
        Faquestions.module.css
*/

.title {
    font-family: 'Qualy';
    font-size: 30px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.7200000286102295px;
}

.container {
    margin-bottom: 6rem;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1100px) {
    .title {
        font-size: 28px;
    }
}