/*
        CardActionList.module.css
*/

.paragraph {
    font-family: 'Roie';
    font-size: 40px;
    font-weight: 400;
    line-height: 51px;
    letter-spacing: .3px;
    text-align: center;
}

.cardList {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    margin-bottom: 4rem;
    margin-left: 2rem;
}

.card {
    flex: 1;
    min-width: 0;
}

@media (max-width: 1100px) {
    .cardList {
        flex-direction: column;
    }  
}

@media (max-width: 500px) {
    .paragraph {
        font-size: 25px;
    }
}