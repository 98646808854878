/* 
        StyledIcon.module.css
*/

.icon {
    width: 20px;
    height: 20.792158126831055px;
    top: 0.603515625px;
    left: 0.6039276123046875px;
    margin-left: -55px;
}