/* 
        ContactForm.module.css 
*/

.imageWithTextContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 7rem;
    padding: 0 2rem;
}

.container {
    background-color: rgba(255, 255, 255, 1);
    padding: 0.2rem 3rem 5rem 3rem;
    box-shadow: 0px 4px 10px rgba(55, 71, 79, 0.1);
    height: 580px;
    width: 34rem;
}

::placeholder {
    font-family: 'Prelia' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
}

.customPhoneInput::placeholder {
    color: rgba(11, 55, 130, 0.45) !important;
}

.customPhoneInput {
    width: 528px !important;
    height: 55px !important;
}

@media (max-width: 940px) {
    .imageWithTextContainer {
        flex-direction: column;
    }

    .customPhoneInput {
        width: 15rem !important;
    }

    .container {
        margin-left: 0;
        margin-top: -14rem;
        justify-content: center;
        box-shadow: 0px 4px 10px rgba(39, 60, 138, 0.6);
        padding: 0.2rem 2rem 4rem 2rem;
        width: fit-content;
    }
}

@media (max-width: 500px) {
    .imageWithTextContainer {
        margin-top: 5rem;
    }

    .container {
        margin-top: 0;
    }
}