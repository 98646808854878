/*
        CardAction.module.css
*/

.logo {
    width: 44px;
    height: 44px;
}

.smallLogo {
    width: 24px;
}