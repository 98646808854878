/*
        ExternalActions.module.css
*/

.block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 2rem;
    height: fit-content;
    margin: 0 2rem 2rem 2rem;
}

@media (max-width: 500px) {
    .block {
        margin-top: 12rem;
    }  
}