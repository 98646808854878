/* 
        FooterListItem.module.css 
*/

.footerListItem {
    font-family: 'Prelia';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
}

@media (max-width: 1220px) {
    .footerListItem {
        font-size: 11px;
        line-height: 8px;
    }
}

@media (max-width: 900px) {
    .footerListItem {
        margin-right: 1rem;
    }
}

@media (max-width: 500px) {
    .footerListItem {
        font-size: 8.8px;
    }
}