/* 
        RequestDemo.module.css 
*/

.imageWithTextContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4rem;
    height: 790px;
    margin-bottom: 3rem;
    margin-right: 3rem;
}

.subTitle {
    font-family: 'Qualy';
    font-size: 32px;
    font-weight: 300;
    line-height: 44px;
    letter-spacing: -0.7200000286102295px;
    text-align: center;
    color: #0B3782;
}

.description {
    font-family: 'Prelia';
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #0B3782;
}

.privacyDescription {
    color: rgba(75, 130, 247, 1);
}

.content {
    display: flex;
    margin-left: -12px;
    padding-top: 0.4rem;
}

.container {
    background-color: rgba(255, 255, 255, 1);
    padding: 0.2rem 3rem 2rem 3rem;
    box-shadow: 0px 4px 10px rgba(55, 71, 79, 0.1);
    height: fit-content;
    margin-left: 6rem;
}

.customPhoneInput::placeholder {
    font-family: 'Prelia' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #0B3782 !important;
}

.customPhoneInput {
    width: 528px !important;
    height: 55px !important;
}

@media (max-width: 1200px) {
    .imageWithTextContainer {
        flex-direction: column;
        margin-top: 6rem;
        margin-bottom: 3rem;
    }

    .secondP {
        display: none;
    }

    .subTitle {
        font-size: 20px;
    }

    .customPhoneInput {
        width: 15rem !important;
    }

    .container {
        margin-left: 0;
        justify-content: center;
        box-shadow: 0px 4px 10px rgba(39, 60, 138, 0.6);
        padding: 0.2rem 2rem 2rem 2rem;
    }
    .description{
        margin-top: 34px;
    }
}