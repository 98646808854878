/* 
        Footer.module.css 
*/

footer {
  background-color: #0B3782;
  height: fit-content;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  text-decoration: none;
  color: inherit;
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: 0 8%;
  padding: 50px 0;
}

.column {
  width: 234.67px;
  height: 145px;
  padding-left: 1%;
}

.columnn {
  padding-left: 3rem;
}

.companyInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4px;
}

.bottomText {
  flex: 1;
}

.iconContainer {
  display: flex;
  gap: 21px;
}


.contactInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contactInfo .MuiSvgIcon-root {
  font-size: 24px;
  color: #222222;
}

.link:hover {
  color: rgba(108, 203, 180, 1);
}

.footerTextContainer {
  white-space: nowrap;
}

.newInfo {
  margin-left: 1.5rem;
  margin-top: -0.7rem;
}

@media (max-width: 1220px) {

  
  .footerContent {
    padding-left: 2rem;
  }

  .imageContainer {
    display: none;
  }

  .columnn {
    margin-left: 2rem;
    margin-top: 2rem;
  }
}

@media (max-width: 900px) {
  .companyI {
    display: flex;
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  }

  .columnn {
    padding-left: 0;
  }

  .footerContent {
    padding-left: 0;
  }
}

@media (max-width: 500px) {
  .columnn {
    margin-left: 0;
  }
  
  .footerContent {
    padding-left: 0;
  }
}