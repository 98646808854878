.listItem {
    font-family: "Prelia";
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.1px;
    color: #0B3782;
    margin: 20px 0px;
}
  
  ul li {
    color: #0B3782; 
  }

@media (max-width: 600px) {
    .listItem {
        line-height: 20px;
    }
}