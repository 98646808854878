/*
    FooterText.module.css
*/

.footerText {
  font-family: 'Prelia';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

@media (max-width: 768px) {
  .footerText{
    font-size: 12px;
  }
}