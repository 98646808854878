/* 
        Rectangle.module.css 
*/

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 77px;
    background-color: #0B3782;
    margin: 10rem 0 4.2rem 0;
}

.header {
    width: 986px;
    height: fit-content;
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    background: linear-gradient(to right, 
    #9B46E3 0%, 
    #89B8EF 25%, 
    #80B5E9 50%, 
    #62ABD7 75%, 
    #5CA9D3 100%);

    margin-top: -5.55%;
}

.header h2 {
    font-family: 'Roie';
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    display: flex;
    color: #FFFFFF;
}

.content {
    display: flex;
    align-items: center;
}

button {
    width: 100px;
    height: 47px;
    background-color: #4B82F7;
    color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
    font-family: 'Prelia';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
}

.btn { 
    background-color: #0B3782;
    font-weight: bold;
    font-size: "14px";
  }

@media (max-width: 980px) {
    .header h2 {
        font-size: 18px;
    }  
    .header {
        width: fit-content;
    }
}

@media (max-width: 450px) {
    button {
        width: fit-content;
        font-size: 12px;
    }
}