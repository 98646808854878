.toolbar {
  display: flex;
  justify-content: space-around;
}

.toolbar::before {
  content: "";
  background-image: url('/public/assets/images/WAVES.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 290px;
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: -1; 
  transform: rotate(-8deg); 
  @media (max-width: 800px) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .toolbar {
    flex-wrap: wrap;
  }
}

@media (max-width: 868px) {
  .containerBar {
      padding-left: 0;
  }
  .toolbar > * {
    margin-right: 0; 
  }
}