/* 
        HeroImageBusinessLines.module.css
*/

@font-face {
    font-family: 'Prelia';
    src: url('../../../public/assets/fonts/Prelia.woff2') format('woff2'),
        url('../../../public/assets/fonts/Prelia.woff') format('woff');
}

.description-block {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 561px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 3% 0;
}

.descriptionTextContainer {
    margin-bottom: 20px;
    margin-top: .1rem;
    width: 90%;
}