/*
        JobsLink.module.css
*/

.descriptionTextContainer {
    display: flex;
    margin: 2rem 5rem;
    gap: 27px;
    flex-wrap: wrap;
}

.descriptionText {
    font-family: 'Prelia';
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.7200000286102295px;
    text-align: left;
    width: 516px;
    height: 44px;
    left: 462px;
    color: #0B3782;
}


.descriptionn {
    font-family: 'Prelia';
    font-size: 24px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.7200000286102295px;
    color: #0B3782;
    margin-top: -1.5rem;
    margin-bottom: 60px;
}

@media (max-width: 800px) {
    .descriptionText {
        font-size: 24px;
        text-align: center;
    }

    .descriptionn {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .descriptionTextContainer {
        margin: 2rem 2rem;
    }

    .descriptionText {
        font-size: 28px;
    }

    .descriptionn {
        font-size: 18px;
    }
}