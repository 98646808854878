/* 
        ImageGallery.module.css 
*/

.imageGalleryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    flex-wrap: wrap;
}

.galleryImage {
    margin: 0 30px;
}