.img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  @media (max-width: 600px){
    height: 300px;
  }
}

.pathSpan {
  color: rgba(0, 0, 0, 0.54);
  font-family: "Prelia";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 0.3rem;
}
