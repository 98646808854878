/* 
        Navbar.module.css 
*/

.appBar {
  box-shadow: none;
  position: absolute;
  width: 100%;
  height: 104px;
  left: 0;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 0;
  margin-left: 5%;
  padding-left: 6%;
}

.centerContent {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 3%;
}

.menuContainer> :not(:last-child) {
  margin-right: 10px;
}

.iconContainer {
  margin-right: 2rem;
}

.icon {
  width: 20px;
  height: 20.792158126831055px;
  top: 0.603515625px;
  left: 0.6039276123046875px;
  margin-left: -55px;
}

.logoSmall {
  width: 9rem;
}

.imgg {
  width: 33px;
  height: 30px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.redTextfield {
  animation: shake 0.3s;
  background-color: rgba(39, 60, 138, 0.1);
}

.textfield {
  background-color: rgba(39, 60, 138, 0.1);
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(-4px);
  }

  75% {
    transform: translateX(4px);
  }

  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1224px) {
  .paragraph{
    color: "#0B3782";
    font-size: 14px;
    margin-bottom: 2rem;
  }
}

@media (max-width: 500px) {
  .logo {
    margin-left: -1.2rem;
  }
  .logoSmall {
    margin-left: -1.2rem;
  }
}