/*
        JobDetail.module.css
*/

.pathSpan {
  font-family: 'Prelia';
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #276DCB;
}

.paragraphFormat {
  font-family: 'Prelia';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0B3782;
  margin-top: 3rem;
}

.parag {
  font-family: 'Verdana';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #0B3782;
  margin-left: 0.5rem;
}

.dateparag {
  font-family: 'Verdana';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0B3782;
  margin-left: 0.2rem;
}

.btns {
  width: fit-content;
  border-radius: 4px;
  background-color: #4b82f7;
}

@media (max-width: 1200px) {
  .dateparag {
    margin-top: 31px;
  }

  .parag {
    margin-top: 31px;
  }
}

@media (max-width: 858px) {
  .boxContainer {
    display: flex;
    flex-direction: column;
    margin-left: 3.7rem;
  }

  .boxContainer>form {
    width: 100%;
    max-width: 300px;
  }

  .btns {
    margin-top: 20px;
    margin-left: -40px;
  }

  input {
    margin-top: 20px;
    margin-left: 30px;
  }

  .dateparag {
    font-size: 10.7px;
  }

  .parag {
    font-size: 11px;
  }
}

@media (max-width: 400px) {
  .parag {
    display: none;
  }

  .dateparag {
    font-size: 9.6px;
  }

  .paragraphFormat {
    margin-top: 1rem;
  }
}