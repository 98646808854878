/* 
        CustomFooterComponent.css 
*/

.textStyle {
    font-family: 'Prelia';
    font-size: 16px;
    font-weight: 200;
    line-height: 20px;
    padding-top: 1%;
    color: #0B3782;
}

.buttonColor {
    color: rgb(11, 55, 130);
    margin-bottom: 0.7rem;
}

.titleStyle {
    padding-top: 1%;
    font-family: 'Roie';
    font-size: 32px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0.7rem;
    color: #0B3782;
}

@media (max-width: 898px) {
    .titleStyle {
        font-size: 25px;
    }
    .textStyle {
        font-size: 12px;
        width: 80%;
        padding-left: 0.5rem;
    }
}