/* 
        CustomFooterComponent.css 
*/

.textStyle {
    font-family: 'Prelia';
    font-size: 17px;
    font-weight: 200;
    line-height: 20px;
    padding-top: 1%;
    color: #0B3782;
    margin-bottom: 0.3rem;
    margin-left: 0.3rem;
}

.container {
    display: flex;
}

@media (max-width: 898px) {
    .textStyle {
        font-size: 11px;
        width: 80%;
    }
}