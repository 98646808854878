/* 
    CustomFooterComponent.css 
*/

.customComponent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #FAFAFA;
  padding: 1.2rem 15%;
}

.content {
  flex: 1;
}

.title {
  font-family: 'Roie';
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #0B3782;
}

@media (max-width: 800px) {
  .customComponent {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px 20px 20px;
  }

  .content {
    margin-top: 0;
  }

  .title {
    font-size: 18px;
    color: #041C56;
  }

  .MuiButton-root {
    margin-top: 20px;
  }
}