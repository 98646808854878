.container {
    display: flex;
    justify-content: space-between;
    gap: 68px;
    margin: 70px 100px 50px;
    flex-wrap: wrap;
}

@media (max-width: 940px) {
    .container {
        padding: 20px 2rem;
        margin: 0px 0;
        text-align: center;
    }
}