/* 
        CardList.module.css 
*/

.paragraph {
    font-family: 'Qualy';
    font-size: 35px;
    font-weight: 300;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: center;
    color: #0B3782;
}

.cardList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.card {
    flex: 1;
    min-width: 0;
}

@media (max-width: 600px) {
    .cardList {
        justify-content: center;
    }
}

@media (max-width: 1024px) {
    .paragraph {
        font-size: 28px;
    }
}