.img1 {
  position: absolute;
  top: -40px;
  z-index: -10;
  @media (max-width: 900px) { 
  display: none;

  }

}

.img2 {
  position: absolute;
  z-index: -10;
  top: -120px;
  right: 0px;

}
