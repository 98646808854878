/*
        ClickableCard.module.css
*/

.logo {
    width: 30%;
}

.Card:hover {
    animation-name: translateY;
    animation-duration: 2s;
    animation-timing-function: linear;
}

@media (max-width: 1200px) {
    .Card:hover {
        animation-name: none; 
    }
}