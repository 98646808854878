/*
        NotFound.module.css
*/

.toolbar {
    text-align: center;
    margin-top: 5.3rem;
}

@media (max-width: 700px) {
    img {
        width: 80%;
    }
}