/* 
        HeroImage.module.css 
*/

@font-face {
    font-family: 'Prelia';
    src: url('../../../public/assets/fonts/Prelia.woff2') format('woff2'),
        url('../../../public/assets/fonts/Prelia.woff') format('woff');
}

.description-block {
    position: relative;
    height: calc(100vh - 222px);
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 9% 0;
    /* background: linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(255, 255, 255, 0.6) 100%);  */
    /* background: rgba(255,255,255 , .13) ; */
    color: #063483;
}

.backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.descriptionTextContainer {
    margin-bottom: 20px;
    margin-top: 2rem;
}

.description-text {
    width: 70%;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

@media (max-width: 1024px) {
    .description-text {
        width: 80%;
    }
}