.container {
  padding: 20px;
}

.img {
  width: 64px;
  height: 64px;
  margin-bottom: -10px;
}

.title {
  color: #0B3782;
  font-family: 'Roie';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.72px;

}

.text {
  color: #0B3782;
  font-family: 'Prelia';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.72px;
  width: 18rem;
}

@media (max-width: 1110px) {
  .title {
    font-size: 25px;
  }

  .text {
    font-size: 15px;
  }
}