/* 
        FooterHeading.module.css 
*/

@font-face {
    font-family: 'Roie';
    src: url('../../../public/assets/fonts/Roie.woff') format('woff2'),
        url('../../../public/assets/fonts/Roie.woff') format('woff');
}

.footerHeading {
    color: #FFFFFF;
    font-family: 'Roie';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    margin-top: -15px;
}