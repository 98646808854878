/* 
        PageLoader.module.css 
*/

.pageLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: RGBA(240, 240, 240, 0.997);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}

.pageLoader img {
    max-width: 100%;
    animation-name: rotateImage;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes rotateImage {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.imageContainer {
    position: relative;
    width: 300px; 
    height: 300px;
}

.outerImage {
    width: 100%; 
    height: 100%;
}

.innerImage {
    position: absolute;
    top: 36%; 
    left: 36%;
    width: 30%; 
}
