/*
        JobsHead.module.css
*/

.container {
    margin: 8.4rem 5rem 0 5rem;
}

.title {
    font-family: 'Qualy';
    font-family: "Roie";
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.7200000286102295px;
    text-align: left;
    color: #0B3782;
}

.paragraph {
    font-family: 'Prelia';
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #0B3782;
}

@media (max-width: 768px) {
    .title {
        font-size: 28px;
    }

    .paragraph {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .container {
        margin: 8.4rem 2rem 0;
    }
}