@font-face {
    font-family: 'Qualy';
    src: url('../../../public/assets/fonts/Qualy.woff2') format('woff2'),
        url('../../../public/assets/fonts/Qualy.woff') format('woff'),
        url('../../../public/assets/fonts/Qualy.otf') format('otf'),
        url('../../../public/assets/fonts/Qualy.ttf') format('ttf');
}

@font-face {
    font-family: 'Roie';
    src: url('../../../public/assets/fonts/Roie.woff2') format('woff2'),
        url('../../../public/assets/fonts/Roie.woff') format('woff'),
        url('../../../public/assets/fonts/Roie.otf') format('otf'),
        url('../../../public/assets/fonts/Roie.ttf') format('ttf');
}

@font-face {
    font-family: 'Prelia';
    src: url('../../../public/assets/fonts/Prelia.woff2') format('woff2'),
        url('../../../public/assets/fonts/Prelia.woff') format('woff'),
        url('../../../public/assets/fonts/Prelia.otf') format('otf'),
        url('../../../public/assets/fonts/Prelia.ttf') format('ttf');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('../../../public/assets/fonts/Poppins-Thin.woff2') format('woff2'),
        url('../../../public/assets/fonts/Poppins-Light.woff') format('woff');
}

.container {
    display: flex;
    align-items: center;
    font-family: 'Prelia';
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #0F172A;
}

.text {
    color:#0B3782;
}